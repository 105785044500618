import Header from "~/shared/components/Header";
import CallToAction from "~/shared/components/CallToAction";
import Footer from "~/shared/components/Footer";
import { Outlet, useLoaderData } from "@remix-run/react";
import { getLayoutDatas } from "~/models/layout.server";
import { json, LoaderFunction } from "@remix-run/node";

type LoaderData = {
  layoutDatas: Awaited<ReturnType<typeof getLayoutDatas>>
}

export const loader: LoaderFunction = async () => {
  let layoutDatas = await getLayoutDatas()
  return json<LoaderData>({ layoutDatas })
}

export default function Layout() {
  let { layoutDatas } = useLoaderData() as LoaderData
  // console.log(layoutDatas)
  let mainMenu = layoutDatas.menu?.data.attributes.main
  let footerMenu = layoutDatas.menu?.data.attributes.footer
  let metaInfo = layoutDatas.metaInfo?.data.attributes
  let cta = layoutDatas.cta?.data.attributes
  let runningText = layoutDatas.runningText.data.attributes

  return (
    <>
      <Header menus={mainMenu} metaInfo={metaInfo} runningText={runningText} />
      <div className="mt-10"></div>

      <Outlet/>

      <CallToAction cta={cta} />
      <Footer metaInfo={metaInfo} menus={footerMenu} />
    </>
  )
}