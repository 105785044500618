import Button from '~/shared/components/Button'

export default function CallToAction({cta}: any) {
  return (
    <section className="px-2 md:px-0 bg-sky-200">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-around items-center py-8">
          <div className="h1 text-2xl font-bold text-gray-800 text-center md:text-left mb-4 md:mb-0">{cta.title}</div>
          <ul className="flex flex-wrap justify-center">
            {cta.button?.map((button: any) => (
              <li className="mr-2 last:mr-0 mb-2" key={button.title}>
                <Button {...button} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}