import { Fragment, useState } from 'react'
import Marquee from "react-fast-marquee"
import { useSpring, animated } from '@react-spring/web'
import Image from "remix-image"

import Button from '~/shared/components/Button'

export default function Header({menus, metaInfo, runningText}: any) {
  // console.log(menus, metaInfo, runningText)

  const [isMenu, setIsMenu] = useState(false)

  const menuSpring = useSpring({ to: { opacity: 1, transform: 'translate(0, 0)' }, from: { opacity: 0, transform: 'translate(0, -2rem)' } })
  const marqueeSpring = useSpring({ delay: 300, to: { opacity: 1, transform: 'translate(0, 0)' }, from: { opacity: 0, transform: 'translate(0, -1rem)' } })

  return (
    <div className="bg-sky-400 w-full z-50 top-0 left-0 border-b border-gray-200 shadow-md bg-cover"
      style={{backgroundImage: "url('/header-bg.jpeg')"}}>
      <animated.nav style={menuSpring} className="container mx-auto px-2 md:px-0 py-4">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" className="flex items-center justify-center">
            <Image
              className="h-12" alt={`Logo ${metaInfo.name}`}
              src={ENV.BACKEND_URL + metaInfo.logo.data.attributes.url}
              loaderUrl="/api/image"
              dprVariants={[1, 3]}
            />
            <span className="text-3xl font-sans font-bold text-white ml-4">{metaInfo.name}</span>
          </a>
          <div className='z-20'>
              <button onClick={() => setIsMenu(!isMenu)} data-collapse-toggle="navbar-cta" type="button" className="inline-flex items-center p-2 text-sm text-white rounded-lg md:hidden hover:text-secondary hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-cta" aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
              </button>
          </div>
          <div className={`${!isMenu ? 'hidden ' : ''}justify-between items-center w-full md:flex md:w-auto md:order-1 transition z-10`} id="navbar-cta">
            <ul className="flex flex-col p-4 md:p-0 mt-4 rounded-lg border border-gray-100 md:flex-row md:space-x-2 md:mt-0 md:text-sm md:font-medium md:border-0">
              {menus?.map((menu: any) => (
                <li className="mb-2 md:mb-0" key={menu.title}>
                  <Button {...menu} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </animated.nav>

      <section className="h-8 bg-white flex justify-center items-center overflow-hidden">
        <Marquee className="text-gray-700 mr-4 overflow-hidden" speed={100} gradientWidth={100} pauseOnHover={true}>
          {runningText.text?.map((item: any) => (
            <Fragment key={"item" + item.id}>
              <animated.span style={marqueeSpring} className="text-sm">{item.text}</animated.span>
              <animated.span style={marqueeSpring} className="text-5xl text-primary mx-4 md:last:hidden">&#183;</animated.span>
            </Fragment>
          ))}
        </Marquee>
      </section>
    </div>
  )
}