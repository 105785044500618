import Image from "remix-image"

export default function Footer({metaInfo, menus}: any) {
  // console.log(menus)

  return (
    <footer className="bg-sky-500 bg-cover" style={{backgroundImage: "url('/footer-bg.jpg')"}}>
      <div className="container mx-auto">
        <div className="py-12 flex flex-col md:flex-row gap-8">
            <div className="px-2 md:px-0 w-full md:w-5/12">
              <Image
                className="mx-auto md:mx-0 h-8 mb-4" alt={`Logo ${metaInfo.name}`}
                src={ENV.BACKEND_URL + metaInfo.logoText.data.attributes.url}
                loaderUrl="/api/image"
                dprVariants={[1, 3]}
              />
              <p className="text-white text-sm text-center md:text-justify mb-6">{metaInfo.description}</p>

              <h2 className="text-center md:text-left mb-4 text-base font-semibold text-white uppercase">Alamat Kantor</h2>
              <p className="text-center md:text-left text-white text-sm">{metaInfo?.address}</p>
            </div>
            <div className="text-center md:text-left px-2 md:px-0 w-full md:w-2/12">
              <h2 className="mb-4 text-base font-semibold text-white uppercase">Perusahaan</h2>
              <ul className="">
                {menus?.map((menu: any) => (
                  <li className="mb-2" key={menu.title}>
                    <a href={menu.link} className="text-base text-white hover:underline">{menu.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-center md:text-left px-2 md:px-0 w-full md:w-3/12">
                <h2 className="mb-4 text-base font-semibold text-white uppercase">Kontak Kami</h2>

                <ul>
                {metaInfo.contact?.map((contact: any) => (
                  <li key={contact.name}>
                    <a href={contact.link} className="inline-flex items-center justify-center mb-2 px-4 py-1 rounded-full text-sm text-sky-500 bg-white hover:scale-105 transition">
                      <span className={`${contact.icon} text-xl mr-2`}></span>
                      <span>{contact.value}</span>
                    </a>
                  </li>
                ))}
                </ul>
            </div>
            <div className="text-center md:text-left px-2 md:px-0 w-full md:w-2/12">
                <h2 className="mb-4 text-base font-semibold text-white uppercase">Sosial Media</h2>

                <ul className="flex justify-center md:justify-start">
                  {metaInfo.social?.map((social: any) => (
                    <li className="mr-4 last:mr-0" key={social.name}>
                      <a href={social.link} target="_blank" rel="noreferrer" title={`Ikuti akun ${social.name} kami`} className="h-10 w-10 flex justify-center items-center rounded-full bg-white text-sky-500 hover:scale-110 transition">
                        <i className={`${social.icon} text-xl`}></i>
                      </a>
                    </li>
                  ))}
                </ul>
            </div>
        </div>
        
      </div>
      <div className="block py-4 px-2 md:px-0 text-sm text-white bg-secondary text-center">
        © {new Date().getFullYear()} <a href="#" className="hover:underline">{metaInfo.name}</a>. All Rights Reserved.
      </div>
    </footer>
  )
}